/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportGet
 */
export interface ReportGet {
    /**
     * 
     * @type {string}
     * @memberof ReportGet
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ReportGet
     */
    filenameOrig?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportGet
     */
    progress?: number;
}

/**
 * Check if a given object implements the ReportGet interface.
 */
export function instanceOfReportGet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;

    return isInstance;
}

export function ReportGetFromJSON(json: any): ReportGet {
    return ReportGetFromJSONTyped(json, false);
}

export function ReportGetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportGet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'filenameOrig': !exists(json, 'filename_orig') ? undefined : json['filename_orig'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
    };
}

export function ReportGetToJSON(value?: ReportGet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'filename_orig': value.filenameOrig,
        'progress': value.progress,
    };
}

