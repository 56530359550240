/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MapViewerClientRegionResponse,
  MapViewerOverviewResponse,
  OAuthAccessTokenRequest,
  OAuthAccessTokenResponse,
} from '../models';
import {
    MapViewerClientRegionResponseFromJSON,
    MapViewerClientRegionResponseToJSON,
    MapViewerOverviewResponseFromJSON,
    MapViewerOverviewResponseToJSON,
    OAuthAccessTokenRequestFromJSON,
    OAuthAccessTokenRequestToJSON,
    OAuthAccessTokenResponseFromJSON,
    OAuthAccessTokenResponseToJSON,
} from '../models';

export interface ApiGrexmanagerMapviewerOauthAuthorizeRetrieveRequest {
    approvalPrompt: ApiGrexmanagerMapviewerOauthAuthorizeRetrieveApprovalPromptEnum;
    clientId: string;
    redirectUri: string;
    responseType: ApiGrexmanagerMapviewerOauthAuthorizeRetrieveResponseTypeEnum;
    state: string;
}

export interface ApiGrexmanagerMapviewerOauthTokenCreateRequest {
    oAuthAccessTokenRequest: OAuthAccessTokenRequest;
}

/**
 * 
 */
export class MapViewerApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving the client region endpoint
     */
    async apiGrexmanagerMapviewerClientRegionRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapViewerClientRegionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/mapviewer/client_region/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapViewerClientRegionResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving the client region endpoint
     */
    async apiGrexmanagerMapviewerClientRegionRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapViewerClientRegionResponse> {
        const response = await this.apiGrexmanagerMapviewerClientRegionRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for authorizing with MapViewer
     */
    async apiGrexmanagerMapviewerOauthAuthorizeRetrieveRaw(requestParameters: ApiGrexmanagerMapviewerOauthAuthorizeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.approvalPrompt === null || requestParameters.approvalPrompt === undefined) {
            throw new runtime.RequiredError('approvalPrompt','Required parameter requestParameters.approvalPrompt was null or undefined when calling apiGrexmanagerMapviewerOauthAuthorizeRetrieve.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexmanagerMapviewerOauthAuthorizeRetrieve.');
        }

        if (requestParameters.redirectUri === null || requestParameters.redirectUri === undefined) {
            throw new runtime.RequiredError('redirectUri','Required parameter requestParameters.redirectUri was null or undefined when calling apiGrexmanagerMapviewerOauthAuthorizeRetrieve.');
        }

        if (requestParameters.responseType === null || requestParameters.responseType === undefined) {
            throw new runtime.RequiredError('responseType','Required parameter requestParameters.responseType was null or undefined when calling apiGrexmanagerMapviewerOauthAuthorizeRetrieve.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling apiGrexmanagerMapviewerOauthAuthorizeRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.approvalPrompt !== undefined) {
            queryParameters['approval_prompt'] = requestParameters.approvalPrompt;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.redirectUri !== undefined) {
            queryParameters['redirect_uri'] = requestParameters.redirectUri;
        }

        if (requestParameters.responseType !== undefined) {
            queryParameters['response_type'] = requestParameters.responseType;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/mapviewer/oauth/authorize/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for authorizing with MapViewer
     */
    async apiGrexmanagerMapviewerOauthAuthorizeRetrieve(requestParameters: ApiGrexmanagerMapviewerOauthAuthorizeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerMapviewerOauthAuthorizeRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for checking if the access token is valid
     */
    async apiGrexmanagerMapviewerOauthResourceRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/grexmanager/mapviewer/oauth/resource/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for checking if the access token is valid
     */
    async apiGrexmanagerMapviewerOauthResourceRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerMapviewerOauthResourceRetrieveRaw(initOverrides);
    }

    /**
     * Endpoint for getting an access token
     */
    async apiGrexmanagerMapviewerOauthTokenCreateRaw(requestParameters: ApiGrexmanagerMapviewerOauthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OAuthAccessTokenResponse>> {
        if (requestParameters.oAuthAccessTokenRequest === null || requestParameters.oAuthAccessTokenRequest === undefined) {
            throw new runtime.RequiredError('oAuthAccessTokenRequest','Required parameter requestParameters.oAuthAccessTokenRequest was null or undefined when calling apiGrexmanagerMapviewerOauthTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/mapviewer/oauth/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OAuthAccessTokenRequestToJSON(requestParameters.oAuthAccessTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OAuthAccessTokenResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for getting an access token
     */
    async apiGrexmanagerMapviewerOauthTokenCreate(requestParameters: ApiGrexmanagerMapviewerOauthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OAuthAccessTokenResponse> {
        const response = await this.apiGrexmanagerMapviewerOauthTokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving the overview map endpoint
     */
    async apiGrexmanagerMapviewerOverviewRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapViewerOverviewResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/mapviewer/overview/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapViewerOverviewResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving the overview map endpoint
     */
    async apiGrexmanagerMapviewerOverviewRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapViewerOverviewResponse> {
        const response = await this.apiGrexmanagerMapviewerOverviewRetrieveRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ApiGrexmanagerMapviewerOauthAuthorizeRetrieveApprovalPromptEnum = {
    Auto: 'auto'
} as const;
export type ApiGrexmanagerMapviewerOauthAuthorizeRetrieveApprovalPromptEnum = typeof ApiGrexmanagerMapviewerOauthAuthorizeRetrieveApprovalPromptEnum[keyof typeof ApiGrexmanagerMapviewerOauthAuthorizeRetrieveApprovalPromptEnum];
/**
 * @export
 */
export const ApiGrexmanagerMapviewerOauthAuthorizeRetrieveResponseTypeEnum = {
    Code: 'code'
} as const;
export type ApiGrexmanagerMapviewerOauthAuthorizeRetrieveResponseTypeEnum = typeof ApiGrexmanagerMapviewerOauthAuthorizeRetrieveResponseTypeEnum[keyof typeof ApiGrexmanagerMapviewerOauthAuthorizeRetrieveResponseTypeEnum];
