/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MapViewerOverviewResponse
 */
export interface MapViewerOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof MapViewerOverviewResponse
     */
    viewerUri: string;
}

/**
 * Check if a given object implements the MapViewerOverviewResponse interface.
 */
export function instanceOfMapViewerOverviewResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewerUri" in value;

    return isInstance;
}

export function MapViewerOverviewResponseFromJSON(json: any): MapViewerOverviewResponse {
    return MapViewerOverviewResponseFromJSONTyped(json, false);
}

export function MapViewerOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapViewerOverviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewerUri': json['viewer_uri'],
    };
}

export function MapViewerOverviewResponseToJSON(value?: MapViewerOverviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewer_uri': value.viewerUri,
    };
}

