/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Report,
  ReportCreate,
  ReportGet,
} from '../models';
import {
    ReportFromJSON,
    ReportToJSON,
    ReportCreateFromJSON,
    ReportCreateToJSON,
    ReportGetFromJSON,
    ReportGetToJSON,
} from '../models';

export interface ApiGrexmanagerReportCreateRequest {
    report: Report;
}

export interface ApiGrexmanagerReportRetrieveRequest {
    uuid: string;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating an Excel report
     */
    async apiGrexmanagerReportCreateRaw(requestParameters: ApiGrexmanagerReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportCreate>> {
        if (requestParameters.report === null || requestParameters.report === undefined) {
            throw new runtime.RequiredError('report','Required parameter requestParameters.report was null or undefined when calling apiGrexmanagerReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/report/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportToJSON(requestParameters.report),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportCreateFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating an Excel report
     */
    async apiGrexmanagerReportCreate(requestParameters: ApiGrexmanagerReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportCreate> {
        const response = await this.apiGrexmanagerReportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for getting an Excel report
     */
    async apiGrexmanagerReportRetrieveRaw(requestParameters: ApiGrexmanagerReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportGet>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiGrexmanagerReportRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/report/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportGetFromJSON(jsonValue));
    }

    /**
     * Endpoint for getting an Excel report
     */
    async apiGrexmanagerReportRetrieve(requestParameters: ApiGrexmanagerReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportGet> {
        const response = await this.apiGrexmanagerReportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
